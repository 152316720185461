@layer base {
  /* those two rules because Prettier is set to 120 and break some stylelint rules around line 260 */
/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable indentation */
.hdr-DropdownMenu {
  display: inline-flex;
  font-size: 1rem;
}

.hdr-DropdownMenu-item {
  display: flex;
  outline: none;
}

.hdr-DropdownMenu-item:focus-within {
  pointer-events: none;
}

.hdr-DropdownMenu-link {
  display: flex;
  position: relative;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-family: var(--fontFamily);
  font-size: var(--smallBody);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.hdr-DropdownMenu-link::after,
.hdr-DropdownMenu-link .hdr-DropdownMenu-arrowIcon {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.hdr-DropdownMenu-link--active {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.hdr-DropdownMenu-link:hover::after,
.hdr-DropdownMenu-link--active::after,
.hdr-DropdownMenu-link--active .hdr-DropdownMenu-arrowIcon {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: var(--sk-space-12);
  width: calc(100% - var(--sk-space-24));
  height: 0.125rem;
  margin: 0;
  padding: 0;
  background-color: var(--themeButtonTextColor, var(--color-primary));
}

.hdr-DropdownMenu-submenu-trigger:global(.dropdown--open) .hdr-DropdownMenu-submenu-trigger___icon__menu,
.hdr-DropdownMenu-submenu-trigger:not(:global(.dropdown--open)) .hdr-DropdownMenu-submenu-trigger___icon__cross {
  display: none;
}

.hdr-DropdownMenu-submenu-trigger:global(.dropdown--open) .hdr-DropdownMenu-submenu-trigger___icon__cross,
.hdr-DropdownMenu-submenu-trigger:not(:global(.dropdown--open)) .hdr-DropdownMenu-submenu-trigger___icon__menu {
  display: block;
}

.hdr-DropdownMenu-submenu {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  flex-direction: column;
  width: 1px;
  height: 1px;
  gap: var(--sk-space-8);
  margin: var(--sk-space-8) 0 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border-radius: var(--sk-radius-4);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: var(--sk-space-16);
  clip: auto;
  background: var(--sk-color-white);
}

.hdr-DropdownMenu-submenuLink {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-8);
  color: inherit;
  text-decoration: none;
}

.hdr-DropdownMenu-submenuLink--active p {
  font-weight: var(--sk-font-weights-poppins-3);
}

.hdr-DropdownMenu-submenuLink:hover {
  background-color: var(--sk-color-grey-025);
}

.hdr-DropdownMenu-submenuLink--withIcon svg {
  width: var(--sk-size-20);
  min-width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.hdr-DropdownMenu-divider {
  display: flex;
}

.hdr-DropdownMenu-submenuLinkIcon {
  padding: 0 1rem 0 0.5rem;
  stroke: var(--themeMainColor, var(--color-primary));
}

.hdr-DropdownMenu-submenuLogo {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  border-radius: var(--sk-radius-4);
}

.hdr-DropdownMenu-linkTextWrapper {
  margin: 0;
  font-weight: 400;
}

.hdr-DropdownMenu-linkTitle {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.hdr-DropdownMenu-linkTitle--active {
  font-weight: var(--font-weight-bold);
}

.hdr-DropdownMenu-linkText {
  margin: 0;
  color: var(--sk-color-grey-500);
  font-size: 0.75rem;
  line-height: 1rem;
}

.hdr-DropdownMenu-linkIcon {
  display: flex;
  align-items: center;
}

.hdr-DropdownMenu-item--user {
  font-weight: var(--font-weight-regular);
}

.hdr-DropdownMenu-item--userActive .hdr-DropdownMenu-link {
  font-weight: var(--font-weight-bold);
}

@media (width < 64rem) {
  /* --medium-and-less-only */
  .hdr-DropdownMenu-linkLabel--hidden {
    display: none;
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .hdr-DropdownMenu-arrowIcon {
    display: none;
  }

  .hdr-DropdownMenu-item--user .hdr-DropdownMenu-link::after {
    content: none;
  }

  .hdr-DropdownMenu-item--user .hdr-DropdownMenu-submenu,
  .hdr-DropdownMenu-item--user:focus-within .hdr-DropdownMenu-submenu {
    --submenuSpacer: 2vw;
    top: var(--sk-space-56);
    left: var(--submenuSpacer);
    box-sizing: border-box;
    width: calc(100vw - var(--submenuSpacer) * 2);
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .hdr-DropdownMenu {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .hdr-DropdownMenu-item--userActive {
    position: relative;
    height: var(--sk-space-64);
  }

  .hdr-DropdownMenu-item--userActive::after,
  .hdr-DropdownMenu-item--userActive .hdr-DropdownMenu-arrowIcon {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    margin: 0;
    padding: 0;
    background-color: var(--sk-color-black);
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
    box-sizing: border-box;
    width: 345px;
  }

  .hdr-DropdownMenu-link--active {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hdr-DropdownMenu-linkIcon {
    display: flex;
    padding-right: 0.5rem;
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-link:not(.hdr-DropdownMenu-link--active)::after,
  .hdr-DropdownMenu-item:focus-within
    .hdr-DropdownMenu-link:not(.hdr-DropdownMenu-link--active)
    .hdr-DropdownMenu-arrowIcon {
    /* stylelint-disable-line max-line-length */
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--active {
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link:hover::after,
[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--active::after {
  background-color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-submenu,
[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-submenuLink {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-submenuLinkIcon {
  stroke: var(--sk-color-black);
}

}